import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Heading, Text } from "gatsby-interface"
import * as qs from "query-string"
import Layout from "../../layout"
import SiteHead from "../../head"
import Container from "../../layout/container"
import Footer from "../../components/agencies/footer"
import Image from "../../components/agencies/image"
import Filters from "../../components/agencies/filters"

const SEARCH_ENDPOINT = "/api/agencies/search"

export interface ContentfulAsset {
  id: string
  title: string
  gatsbyImageData?: IGatsbyImageData
  url?: string
  width?: number
  height?: number
}

export interface SocialMediaImage {
  id: string
  resize: {
    src: string
  }
}

export type AgencyImage = ContentfulAsset

export interface ContentfulLongText {
  childMarkdownRemark: {
    html: string
  }
}

export interface AgencySite {
  id?: string
  name: string
  url: string
  image: {
    id?: string
    gatsbyImageData: IGatsbyImageData
  }
}

export interface Agency {
  id: string
  name: string
  slug: string
  logo: AgencyImage
  introduction: ContentfulLongText
  specialization: ContentfulLongText
  platforms: string[]
  expertises: string[]
  tags: string[]
  awards: ContentfulAsset[]
  sites: AgencySite[]
  website: string
  email: string
  phone: string
  regions: string[]
  languages: string[]
  socialMediaImage: SocialMediaImage
}

interface AgencyAwardTagProps {
  tags: String[]
}

function AgencyAwardTag({ tags }: AgencyAwardTagProps) {
  // consider adding an enum and prepopulating content model
  if (!tags?.includes("gatsby-agency-award-winner")) return null
  return (
    <div
      css={theme => ({
        fontSize: theme.fontSizes[1],
        color: theme.colors.gatsby,
      })}
    >
      Agency Award Winner
    </div>
  )
}

function AgencyCard({ id, name, slug, logo, regions, tags }: Agency) {
  return (
    <Link
      to={slug}
      css={theme => ({
        display: "block",
        textDecoration: "none",
        color: "inherit",
        borderRadius: theme.radii[3],
        boxShadow: theme.shadows.raised,
        transition: theme.transitions.default,
        "&:hover": {
          boxShadow: theme.shadows.overlay,
        },
      })}
    >
      <div>
        <div
          css={theme => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: `10rem`,
            padding: `${theme.space[8]} ${theme.space[6]}`,
          })}
        >
          <Image
            {...logo}
            css={theme => [
              {
                maxWidth: `100%`,
                [theme.mediaQueries.phablet]: {
                  maxWidth: `280px`,
                },
              },
              !logo?.gatsbyImageData && {
                width: `100%`,
                height: `auto`,
                objectFit: `contain`,
                maxHeight: `64px`,
                maxWidth: `280px`,
              },
            ]}
          />
        </div>
        <div
          css={theme => ({
            padding: theme.space[6],
          })}
        >
          <Heading
            css={theme => ({
              fontSize: theme.fontSizes[3],
            })}
          >
            {name}
          </Heading>

          {regions && (
            <div
              css={theme => ({
                fontSize: theme.fontSizes[1],
                color: theme.colors.grey[80],
              })}
            >
              {regions.join(`, `)}
            </div>
          )}
          <AgencyAwardTag tags={tags} />
        </div>
      </div>
    </Link>
  )
}

interface AgencyIndexProps {
  location: Location
  data: {
    agencies: {
      nodes: Agency[]
    }
  }
}

export interface AgencyFilters {
  regions?: string[]
  languages?: string[]
  expertises?: string[]
  platforms?: string[]
}

export function Head(props) {
  return <SiteHead {...props} title="Agency Directory" />
}

export default function AgencyIndex(props: AgencyIndexProps) {
  const [agencies, setAgencies] = React.useState(props.data.agencies.nodes)
  const initFilters = qs.parse(props.location.search, {
    arrayFormat: "bracket",
  })
  const [filters, setFilters] = React.useState<AgencyFilters>(initFilters)
  const init = React.useRef(true)

  React.useEffect(() => {
    const query = qs.stringify(filters, {
      arrayFormat: "bracket",
    })
    navigate(props.location.pathname + "?" + query)
    if (!init.current || query) {
      filterResults()
    }
    init.current = false
  }, [filters])

  const filterResults = async () => {
    const data = await fetch(SEARCH_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filters),
    }).then(res => res.json())

    if (data.error) {
      console.error(data.message, data.error)
      return
    }
    if (data.agencies) {
      setAgencies(data.agencies)
    }
  }

  return (
    <Layout>
      <Container>
        <div
          css={theme => ({
            paddingTop: theme.space[6],
            paddingBottom: theme.space[6],
          })}
        >
          <Heading
            as="h1"
            css={theme => ({
              marginBottom: theme.space[4],
            })}
          >
            Gatsby Agency Partners
          </Heading>
          <Text>
            Gatsby works with the best web development agencies in the world.
            <br />
            Find a Gatsby-savvy agency to help you supercharge your website
            today, <br /> or{" "}
            <Link to="/agencies/submissions">submit your agency</Link> to the
            Gatsby Agency Directory.
          </Text>
        </div>

        <div
          css={theme => ({
            display: `grid`,
            gap: theme.space[10],
            alignItems: `start`,

            [theme.mediaQueries.phablet]: {
              gridTemplateColumns: `minmax(180px, auto) 1fr`,
            },
          })}
        >
          <div
            css={theme => ({
              display: `none`,
              [theme.mediaQueries.phablet]: {
                display: "block",
              },
            })}
          >
            <Filters value={filters} onChange={setFilters} />
          </div>

          {!agencies.length ? (
            <div
              css={theme => ({
                textAlign: "center",
                paddingTop: theme.space[10],
                paddingBottom: theme.space[10],
              })}
            >
              No results found
            </div>
          ) : (
            <div
              css={theme => ({
                display: "grid",
                gap: theme.space[6],
                marginBottom: theme.space[8],
                gridTemplateColumns: "1fr",

                [theme.mediaQueries.desktop]: {
                  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                },
              })}
            >
              {agencies.map(agency => (
                <AgencyCard key={agency.id} {...agency} />
              ))}
            </div>
          )}
        </div>

        <Footer />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query AgencyIndex {
    agencies: allContentfulAgency(sort: { fields: [tags, name], order: ASC }) {
      nodes {
        id
        name
        slug
        logo {
          id
          gatsbyImageData(layout: CONSTRAINED, height: 64)
          url
          width
          height
        }
        regions
        tags
      }
    }
  }
`
